import {Form, Point} from "@/types/pages/Login.type";

const FORM = (): Form => ({
    username: "",
    phone: "",
    password: "",
})

const FIGURE_POSITION = (): Point[] => ([
    {x: 0, y: 0},
])

export {
    FORM,
    FIGURE_POSITION
}
