<template>
    <div>
        <NotificationsView/>
        <blocked-user v-if="isLocked" :figurePos="figurePos" @reset:login="resetLogin"/>
        <authorization-layout
            v-else
            ref="authorization-layout"
            v-model="form"
            :passwordVisible="passwordVisible"
            :isLoading="isLoading"
            :lockTimes="lockTimes"
            :isShowInputError="isShowInputError"
            @toggle:passwordVisible="togglePasswordVisible"
            @on:submit="check"
        />
    </div>
</template>

<script>
import {mapMutations} from "vuex";
import {CommonMixin} from "@/mixins/Common.mixin";
import {login, authCheck, getUser, storeData} from "@/helpers/api";
import { FORM, FIGURE_POSITION } from "@/data/pages/Login"

export default {
    name: "Login",
    mixins: [CommonMixin],
    components: {
        NotificationsView: () => import("@/components/layout/NotificationsView.vue"),
        BlockedUser: () => import("@/components/pages/Login/BlockedUser.vue"),
        AuthorizationLayout: () => import("@/components/pages/Login/AuthorizationLayout.vue")
    },
    data: () => ({
        form: new FORM(),
        figurePos: new FIGURE_POSITION(),
        isLoading: false,
        lockTimes: 3,
        isShowInputError: false,
        passwordVisible: false
    }),
    computed: {
        isLocked() {
            return this.lockTimes < 1
        }
    },
    methods: {
        ...mapMutations({
            setLoggedIn: "LOGGED_IN",
            setOperatorInfo: "operator_info",
        }),
        resetLogin() {
            this.lockTimes = 3;
            this.form = new FORM();

            if (this.$refs["authorization-layout"]?.$refs?.form) {
                this.$refs["authorization-layout"].$refs.form.$v.$reset();
            }
        },
        check() {
            this.isLoading = true;

            login("/login", this.form)
                .then(({data}) => {
                    if (data.success) {
                        const {token, user: {role, username, phone}} = data;

                        const User = {
                            token,
                            role,
                            username,
                            phone,
                            password: this.form.password
                        };
                        storeData(User);

                        this.setLoggedIn(User); // It's mutation

                        this.fetchUser();

                        this.$router.push("/");
                    } else {
                        this.handleLoginError(data.code)

                        // If authorization is unsuccessful, reduce attempt
                    }
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        handleLoginError(code) {
          switch (code) {
              case "VALIDATION":
                  this.lockTimes--;
                  this.$store.commit('notifications/error', {title: "requestsStatuses.failed.authorized", text: this.$t("errors.invalidLoginOrPassword"), type: "error"})
                  break;
              case "LICENSE_EXPIRED":
                  this.isShowInputError = true;
                  this.$store.commit('notifications/error', {title: "requestsStatuses.failed.licence", text: this.$t("errors.expiredLicence"), type: "error"})
                  break;
              default:
                  this.lockTimes--
          }
        },
        fetchUser() {
            getUser()
                .then(({data}) => this.setOperatorInfo(data));  // It's mutation;
        },
        togglePasswordVisible() {
            this.passwordVisible = !this.passwordVisible
        }
    },
    watch: {
        '$store.state.notifications.list': {
            handler($val) {
                if (Array.isArray($val) && $val.length === 0) {
                    this.isShowInputError = false;
                }
            },
            immediate: true
        }
    },
    mounted() {
        if (authCheck()) {
            return this.$router.push("/");
        }
    },
};
</script>
<style src="@/assets/scss/modules/pages/Login.scss" lang="scss"></style>
